import React, { Component } from 'react';
import ResignationResults from './resignation-results';
import BestMonthlyResignations from './best-monthly-resignations';
import BakeryMap from './bakery-map';

export default class ResignationTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_details: true,
      show_bakeries: false,
    };

    this.onChangeResignationDetails = this.onChangeResignationDetails.bind(this);
    this.onChangeBakeries = this.onChangeBakeries.bind(this);
  }

  onChangeResignationDetails(event) {
    event.preventDefault();
    this.setState({
      show_details: true,
      show_bakeries: false
    })
  }

  onChangeBakeries(event) {
    event.preventDefault();
    this.setState({
      show_details: false,
      show_bakeries: true
    });
  }

  resignationDetails() {
    return (
      <div>
        <ResignationResults results={this.props.resignation_details} />
        <BestMonthlyResignations results={this.props.best_in_month} />
      </div>
    )
  }

  bakeryDetails() {
    return (
      <BakeryMap />
    )
  }

  detailsTab() {
    if (this.state.show_details) {
      return (
        <li class="mr-3 rounded	bg-green">
          <button onClick={this.onChangeResignationDetails} class="inline-block py-3 px-4 text-sm font-medium text-center text-white">Details</button>
        </li>
      )
    }
    else {
      return (
        <li class="mr-3 rounded	bg-purple bg-opacity-0">
          <button onClick={this.onChangeResignationDetails} href="#" aria-current="page" class="inline-block py-3 px-4 text-sm font-medium text-center text-white">Details</button>
        </li>
      )
    }
  }

  bakeriesTab() {
    if (this.state.show_bakeries) {
      return (
        <li class="mr-3 rounded	bg-green">
          <button onClick={this.onChangeBakeries} class="inline-block py-3 px-4 text-sm font-medium text-center text-white">Ideas</button>
        </li>
      )
    }
    else {
      return (
        <li class="mr-3 rounded	bg-purple bg-opacity-0">
          <button onClick={this.onChangeBakeries} class="inline-block py-3 px-4 text-sm font-medium text-center text-white">Ideas</button>
        </li>
      )
    }
  }

  render() {
    return (
      <div class="sm:w-full md:w-11/12 lg:w-8/12 xl:w-7/12 2xl:w-6/12">
        <ul class="flex justify-center mb-5">
          {this.detailsTab()}
          {this.bakeriesTab()}
        </ul>

        {this.state.show_details ? this.resignationDetails() : this.bakeryDetails()}
      </div>
    )
  }
}