import React, { Component } from 'react';
import moment from 'moment'

export default class ResignationResults extends Component {
  render() {
    const holidaysList = this.props.results["holidayDetails"]["holidays"].map((item, index) => 
      <div class="py-2" key={index}>
        <li class="text-light_grey">{item["name"]}</li>
        <span class="text-lightpurple text-xs">{moment(item["dateDetails"]["date"],'YYYY-MM-DD').format('MMM DD, YYYY (ddd)')}</span>
      </div>
    );

    return (
      <div>
        <div class="mb-10 mx-10 px-5 border border-green rounded">
          <div class="flex my-5 py-3 px-3 border rounded-full bg-light_grey bg-opacity-10">
            {
              this.props.results["holidayDetails"]["totalHolidays"] !== 0 ? 
                <h2 class="text-3xl px-2">&#129297;</h2> : 
                <h2 class="text-3xl px-2">&#129324;</h2>
            }
            <div>
              <p class="text-light_grey text-sm font-semibold">Working Details</p>
              {
                this.props.results["holidayDetails"]["totalHolidays"] !== 0 ? 
                  <p class="text-lightpurple text-xs">Break well deserved!</p> : 
                  <p class="text-lightpurple text-xs">You'll have to work everyday!</p>
              }
            </div>
          </div>

          <div class="my-5">
            <div class="flex justify-between">
              <h3 class="text-light_grey font-medium">
                Total working days
              </h3>
              <p class="text-light_grey font-medium">
                {JSON.stringify(this.props.results["mondayToFriday"]["totalWorkingDays"])}
              </p>
            </div>
            <p class="text-lightpurple text-xs">If you work just weekdays</p>
          </div>

          <div class="my-5">
            <div class="flex justify-between">
              <h3 class="text-light_grey font-medium">
                Total working days
              </h3>
              <p class="text-light_grey font-medium">
                {JSON.stringify(this.props.results["mondayToSaturday"]["totalWorkingDays"])}
              </p>
            </div>
            <p class="text-lightpurple text-xs">If you work Monday to Saturday</p>
          </div>

          <div class="my-5">
            <div class="flex justify-between">
              <h3 class="text-light_grey font-medium">
                Total working days
              </h3>
              <p class="text-light_grey font-medium">
                {JSON.stringify(this.props.results["mondayToSunday"]["totalWorkingDays"])}
              </p>
            </div>
            <p class="text-lightpurple text-xs">If you work literally every day</p>
          </div>

          <div class="my-5">
            <div class="flex justify-between">
              <h3 class="text-light_grey font-medium">
                Last day
              </h3>
              <p class="text-light_grey font-medium">
                {moment(this.props.results["lastWorkingDay"], 'YYYY-MM-DD').format('MMM DD, YYYY')}
              </p>
            </div>
            <p class="text-lightpurple text-xs">That's a {moment(this.props.results["lastWorkingDay"], 'YYYY-MM-DD').format('dddd')}</p>
          </div>
        </div>

        <div class="mb-10 mx-10 px-5 border border-green rounded">
          <div class="flex my-5 py-3 px-3 border rounded-full bg-light_grey bg-opacity-10">
            {
              this.props.results["holidayDetails"]["totalHolidays"] !== 0 ? 
                <h2 class="text-3xl px-2">&#129395;</h2> : 
                <h2 class="text-3xl px-2">&#128557;</h2>
            }
            <div>
              <p class="text-light_grey text-sm font-semibold">Holiday Details</p>
              {
                this.props.results["holidayDetails"]["totalHolidays"] !== 0 ? 
                  <p class="text-lightpurple text-xs">Great time to resign!</p> : 
                  <p class="text-lightpurple text-xs">There are no holidays!</p>
              }
            </div>
          </div>

          <div class="my-2 flex justify-between">
            <h3 class="text-light_grey font-medium">
              Holidays on weekends
            </h3>
            <p class="text-light_grey font-medium">
              {JSON.stringify(this.props.results["holidayDetails"]["totalHolidaysOnWeekend"])}
            </p>
          </div>

          <div class="my-2 flex justify-between">
            <h3 class="text-light_grey font-medium">
              Holidays on weekdays
            </h3>
            <p class="text-light_grey font-medium">
              {JSON.stringify(this.props.results["holidayDetails"]["totalHolidaysOnWeekdays"])}
            </p>
          </div>

          <div>
            <div class="my-2 flex justify-between">
              <h3 class="text-light_grey font-medium">
                Total Holidays
              </h3>
              <p class="text-light_grey font-medium">
                {JSON.stringify(this.props.results["holidayDetails"]["totalHolidays"])}
              </p>
            </div>
            <ul class="pb-5">
              {holidaysList}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}