import React, { Component } from 'react';
import axios from 'axios';
import ResignationTabs from './resignation-tabs';
import { COUNTRIES } from '../constants/countries';
import { NOTICE_PERIODS } from '../constants/notice-periods'
import IntroBanner from './intro-banner';
import moment from 'moment'

export default class ResignCalculationForm extends Component {
  constructor(props) {
    super(props)

    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeResignationDate = this.onChangeResignationDate.bind(this);
    this.onChangeNoticePeriod = this.onChangeNoticePeriod.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      country: Object.values(COUNTRIES)[0],
      resignation_date: new Date().toISOString().slice(0, 10),
      notice_period: Object.values(NOTICE_PERIODS)[0],
      resignation_results: {},
      best_resignation_times_results: {}
    };
  }

  onChangeCountry(event) {
    this.setState({
      country: event.target.value
    })
  }

  onChangeResignationDate(event) {
    this.setState({
      resignation_date: event.target.value
    });
  }

  onChangeNoticePeriod(event) {
    this.setState({
      notice_period: event.target.value
    })
  }

  onSubmit(e) {
    e.preventDefault();

    const resignationObject = {
      params: {
        country: this.state.country.toLowerCase().replace(/\s/g, ''),
        resignation_date: this.state.resignation_date,
        notice_period: this.state.notice_period
      }
    };

    axios.get("https://backend.whentoresign.com/workdays", resignationObject).then((response) => {
      this.setState({
        resignation_results: response.data
      });
    }).catch((error) => {
      console.log(error)
    });

    axios.get("https://backend.whentoresign.com/bestmonth", resignationObject).then((response) => {
      this.setState({
        best_resignation_times_results: response.data
      });
    }).catch((error) => {
      console.log(error)
    });
  }

  render() {
    const countries = Object.values(COUNTRIES).map((item, index) =>
      index === 0 ? <option key={index} value={item} selected>{item}</option> : <option key={index} value={item}>{item}</option>
    );

    const notice_period = Object.values(NOTICE_PERIODS).map((item, index) => 
      index === 0 ? <option key={index} value={item} selected>{item}</option> : <option key={index} value={item}>{item}</option>
    );

    return (
      <div class="flex grid grid-cols-1 sm:place-items-center">
        <div class="rounded-xl">
          <div class="max-w-screen-md mx-auto flex justify-center py-5 px-10 md:px-20">
            <img src="logo.png" />
          </div>

          <IntroBanner />

          <form class="mt-5 mb-10 mx-10" onSubmit={this.onSubmit}>
            
            <div class="grid grid-cols-2 gap-4">
              <div class="my-5">
                <p class="font-extralight py-2 text-lg text-white">Country</p>
                <select onChange={this.onChangeCountry} class="form-select appearance-none block w-full bg-purple bg-opacity-0 text-light_grey border border-green px-3 py-1.5 text-base font-light rounded">
                  {countries}
                </select>
              </div>

              <div class="my-5">
                <p class="font-light py-2 text-lg text-light_grey">Notice Period</p>
                <select onChange={this.onChangeNoticePeriod} class="form-select appearance-none block w-full bg-purple bg-opacity-0 text-light_grey border border-green px-3 py-1.5 text-base font-light rounded">
                  {notice_period}
                </select>
              </div>
            </div>

            <div>
              <label>
                <p class="font-light py-2 text-lg text-light_grey">Resignation Date</p>
                <input type="date" min={moment().startOf('year').format('YYYY-MM-DD')} max={moment().endOf('year').format('YYYY-MM-DD')} value={this.state.resignation_date} onChange={this.onChangeResignationDate} class="appearance-none block w-full bg-purple bg-opacity-0 text-light_grey border border-green rounded py-3 px-4 mb-3 leading-tight"/>
              </label>
            </div>

            <div className="form-group">
              <button class="bg-green text-white font-medium py-2 px-10 mt-5 rounded-3xl" type="submit">Submit</button>
            </div>
          </form>
        </div>

        {Object.keys(this.state.resignation_results).length !== 0 && Object.keys(this.state.best_resignation_times_results).length !== 0 
          ? <ResignationTabs resignation_details={this.state.resignation_results} best_in_month={this.state.best_resignation_times_results}/>
          : "" 
        }
      </div>
    )
  }
}