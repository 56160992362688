import './App.css';
import ResignCalculationForm from './components/resign-calculation-form';

function App() {
  return (
    <div>
      <ResignCalculationForm />
      <div class="flex justify-center mb-5">
        <p class="px-2 pt-3.5 text-light_grey text-xs">Sneak peak of the</p>
        <a class="text-green mb-1 border p-2 rounded" href="https://whentoresign.com/architecture.png" target="_blank">architecture</a>
        <p class="px-2 pt-3.5 text-light_grey text-xs">behind this site!</p>
      </div>
    </div>
  );
}

export default App;
