import React, { Component } from 'react';
import moment from 'moment'

export default class BestMonthlyResignations extends Component {
  render() {
    const resignationDates = this.props.results["bestResignationTimes"].map((item, index) => 
      <div class="py-2" key={index}>
        <li class="text-light_grey font-medium">{moment(item["date"],'YYYY-MM-DD').format('MMM DD, YYYY (ddd)')}</li>
        <p class="text-lightpurple text-xs">You will need to work only {item['numberOfWorkdays']} days</p>
      </div>
    );

    return (
      <div>
        <div class="mb-10 mx-10 px-5 border border-green rounded">
          <div class="flex my-5 py-3 px-3 border rounded-full bg-light_grey bg-opacity-10">
            <h2 class="text-3xl px-2">&#129300;</h2>
            <div>
              <p class="text-light_grey text-sm font-semibold">Need to resign in {moment(this.props.results["resignationMonth"], 'M').format('MMMM')}?</p>
              <p class="text-lightpurple text-xs">The most optimal times are...</p>
            </div>
          </div>
          <ul class="pb-5">
            {resignationDates}
          </ul>
        </div>
      </div>
    )
  }
}