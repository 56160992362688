import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react'
import axios from 'axios';

export default class BakeryMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      latitude: 0,
      longitude: 0,
      locationError: true,
      bakeries: [],
    };
  }

  getBakeries = (latitude, longitude) => {
    const locationQueryParams = {
      params: {
        latitude: latitude,
        longitude: longitude
      }
    };

    axios.get("https://search.whentoresign.com/bakeries", locationQueryParams).then((response) => {
      this.setState({
        bakeries: response.data.bakeries || []
      });
    }).catch((error) => {
      console.log(error)
    });
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({ 
          latitude: position.coords.latitude, 
          longitude: position.coords.longitude,
          locationError: false 
        },
        this.getBakeries(position.coords.latitude, position.coords.longitude)
        );
      }, error => {
          this.setState({
            locationError: true
          })
      });
    } else {
      this.setState({
        locationError: true
      })
    }
  }

  BakeryMap() {
    const location = {
      lat: this.state.latitude,
      lng: this.state.longitude,
    }

    const mapStyles = [
      { elementType: "geometry", stylers: [{ color: "#091143" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#CCB7E5" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#10B97C" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ];

    return (
      <div class="h-96">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAY-Lt0U1aQ8HNa61FWqyoql7zq_IQS6c4' }}
          defaultCenter={location}
          defaultZoom={15}
          options={{ styles: mapStyles }}
        >
          <p class="text-4xl"lat={this.state.latitude} lng={this.state.longitude}>&#128205;</p>
          {this.state.bakeries.length !== 0 ? this.BakeryPins() : ""}
        </GoogleMapReact>
      </div>
    )
  }

  BakeryPins() {
    return this.state.bakeries.map((bakery,index) =>
      <div key={index} lat={bakery.location.geometry.latitude} lng={bakery.location.geometry.longitude}>
        <p class="text-4xl">&#127874;</p>
      </div> 
    )
  }

  Bakeries() {
    return this.state.bakeries.map((bakery, index) =>
      <div key={index} class="my-5">
        <h3 class="text-light_grey font-medium">
          {bakery.name}
        </h3>
        <p class="text-lightpurple text-xs">{bakery.location.address}</p>
      </div>
    )
  }

  Message() {
    return (
      <div>
          <div class="flex py-3 px-3 border rounded-full bg-light_grey bg-opacity-10">
            <h2 class="text-3xl px-2">
              {this.state.locationError
                ? String.fromCodePoint(parseInt('1F648', 16)) 
                : String.fromCodePoint(parseInt('1F370', 16))
              }
            </h2>
            <div>
              <p class="text-light_grey text-sm font-semibold">
                {this.state.locationError 
                  ? "Oops, there's a problem" 
                  : "Goodbye pastries"
                }
              </p>
              <p class="text-lightpurple text-xs">
                {this.state.locationError 
                  ? "Please enable location" 
                  : "It's a piece of cake!"
                }
              </p>
            </div>
          </div>
        </div>
    )
  }

  render() {
    return (
      <div class="mb-10 mx-10 px-5 py-5 border border-green rounded">
        {this.Message()}
        {this.Bakeries()}
        {!this.state.locationError && this.state.latitude !== 0 && this.state.longitude !== 0 && this.state.bakeries.length !== 0 ? this.BakeryMap() : ""}
      </div>
    )
  }
}