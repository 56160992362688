import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';

export default class IntroBanner extends Component {
  render() {
    return (
      <div class="border rounded bg-light_grey bg-opacity-10 mx-10 pb-3">
        <p class="px-5 py-3 text-light_grey text-center font-bold">Be the boss of when you tell your boss...</p>
        <p class="px-5 text-light_grey text-center font-extralight">Have a date you want to resign by? We will figure out all the important details for you.</p>
        {/* <div class="flex pt-5 justify-center">
          <span class="px-2 pt-3.5 text-light_grey text-center font-bold">Say</span>
          <SocialIcon url="https://www.linkedin.com/in/steven-l-13603720b/" bgColor="#10B97C" fgColor="#FFF" />
          <span class="px-2 pt-3.5 text-light_grey text-center font-bold">Hello</span>
        </div> */}
      </div>
    )
  }
}